type Provider = "johndeere";

/**
 * Composable defining a repository for pending OAuth authorizations.
 *
 * This composable allows the application to track the initiator for an OAuth authorization and perform any
 * post-authorization tasks as required.
 *
 * Internally uses the client's local storage as this is user activity based and does not need to be shared.
 */
export default () => {
  // Initialize the local storage composable immediately
  const storage = useLocalStorage<Record<string, Provider>>(
    "pending-oauth-authorizations",
    {}
  );

  function clear() {
    storage.value = {};
  }

  function get(code: string): Provider | undefined {
    return storage.value[code] ?? undefined;
  }

  function has(code: string) {
    return Object.prototype.hasOwnProperty.call(storage.value, code);
  }

  function remove(code: string) {
    delete storage.value[code];
  }

  function set(code: string, provider: Provider) {
    storage.value[code] = provider;
  }

  return { clear, get, has, remove, set };
};
