<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps<{
  error: NuxtError;
}>();

useHead({
  title: `${props.error.statusCode} - ${props.error.statusMessage} | MERL`,
});

const lastErrorId = useState<string | undefined>(
  "sentry_last_error",
  () => undefined
);

const errorMessage = computed(
  () => props.error.message ?? props.error.statusMessage ?? undefined
);

async function handleError() {
  await clearError({ redirect: "/" });
}
</script>

<template>
  <div
    class="dark:bg-background-100 dark:text-background-600 flex min-h-screen bg-background-600"
  >
    <div
      class="relative flex flex-1 flex-col justify-center px-6 py-12 lg:w-2/5 lg:flex-none"
    >
      <div class="relative mx-auto w-full max-w-sm">
        <div>
          <h2 class="text-2xl text-center font-semibold">
            {{ error.statusCode }}
          </h2>
          <div
            v-if="errorMessage"
            class="text-center my-4"
            v-text="errorMessage"
          />

          <div
            v-if="error.statusCode === 500 && lastErrorId"
            class="text-center text-sm my-4"
          >
            If you contact support about this error, please reference this
            message ID so our team can investigate further: {{ lastErrorId }}
          </div>

          <div class="mt-6">
            <div class="block w-full rounded-md shadow-sm">
              <ButtonsBase
                type="button"
                color="primary"
                class="!h-11 w-full cursor-pointer"
                @click="handleError"
              >
                Back to Dashboard
              </ButtonsBase>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-muted-100 dark:bg-muted-900 relative hidden w-0 flex-1 items-center justify-center lg:flex lg:w-3/5"
    >
      <NuxtImg
        src="/goodiemachine.jpg"
        class="absolute top-0 left-0 object-cover w-full h-full"
      />
    </div>
  </div>
</template>
