import { default as indexfGRdPY8dw3Meta } from "/workspace/pages/auth/index.vue?macro=true";
import { default as calendarDIaBjG7u9CMeta } from "/workspace/pages/calendar.vue?macro=true";
import { default as indexFwHoINrwDKMeta } from "/workspace/pages/dashboard/[workday_id]/index.vue?macro=true";
import { default as index2lrBtCOh90Meta } from "/workspace/pages/dashboard/index.vue?macro=true";
import { default as indexNsJfxUZ0FKMeta } from "/workspace/pages/equipment/[equipment_id]/index.vue?macro=true";
import { default as add2lHBgjEr9QMeta } from "/workspace/pages/equipment/add.vue?macro=true";
import { default as indexpw3y5IdB0BMeta } from "/workspace/pages/equipment/index.vue?macro=true";
import { default as sendgrid_45tester5dokEmt9lTMeta } from "/workspace/pages/examples/sendgrid-tester.vue?macro=true";
import { default as twilio_45sms_45testerOX27vnPy7vMeta } from "/workspace/pages/examples/twilio-sms-tester.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as indexTECrFyfG3xMeta } from "/workspace/pages/inspections/[inspection_id]/index.vue?macro=true";
import { default as createJBzxGWekoSMeta } from "/workspace/pages/inspections/create.vue?macro=true";
import { default as indexIZgZHKXMoHMeta } from "/workspace/pages/inspections/index.vue?macro=true";
import { default as indexZnV8VF1ja4Meta } from "/workspace/pages/jobsites/[jobsite_id]/index.vue?macro=true";
import { default as index8gHmhEyrfOMeta } from "/workspace/pages/jobsites/[jobsite_id]/tasks/[task_id]/index.vue?macro=true";
import { default as indexpZ87DAld8sMeta } from "/workspace/pages/jobsites/index.vue?macro=true";
import { default as jobsites_45and_45vehiclesPlpQoZsan6Meta } from "/workspace/pages/maps/jobsites-and-vehicles.vue?macro=true";
import { default as callbackOmDf40drZUMeta } from "/workspace/pages/oauth/callback.vue?macro=true";
import { default as accountciOgd6l6U4Meta } from "/workspace/pages/settings/account.vue?macro=true";
import { default as categoriesngQVzCmgCiMeta } from "/workspace/pages/settings/categories.vue?macro=true";
import { default as crewseWhOM6uUqbMeta } from "/workspace/pages/settings/crews.vue?macro=true";
import { default as indexeK6rNZS014Meta } from "/workspace/pages/settings/exceptions/[exception_id]/index.vue?macro=true";
import { default as index0NlQ4FDgWHMeta } from "/workspace/pages/settings/index.vue?macro=true";
import { default as lenders0ONkurflXDMeta } from "/workspace/pages/settings/lenders.vue?macro=true";
import { default as indexa9NYmlF0R6Meta } from "/workspace/pages/settings/pto/[pto_id]/index.vue?macro=true";
import { default as tagsT2FXURAtvjMeta } from "/workspace/pages/settings/tags.vue?macro=true";
import { default as _91user_id_93yPjZ3TdGeoMeta } from "/workspace/pages/settings/users/[user_id].vue?macro=true";
import { default as indexgIPIiIeuS6Meta } from "/workspace/pages/settings/users/index.vue?macro=true";
import { default as vendorslzktSx9bI0Meta } from "/workspace/pages/settings/vendors.vue?macro=true";
import { default as indexztbNX0t2LuMeta } from "/workspace/pages/workorders/[workorder_id]/index.vue?macro=true";
import { default as createtwDLh0TmqaMeta } from "/workspace/pages/workorders/create.vue?macro=true";
import { default as indexMszVj8d4c2Meta } from "/workspace/pages/workorders/index.vue?macro=true";
import { default as shop_45schedulerIaLhx40TgcMeta } from "/workspace/pages/workorders/shop-scheduler.vue?macro=true";
export default [
  {
    name: indexfGRdPY8dw3Meta?.name ?? "auth",
    path: indexfGRdPY8dw3Meta?.path ?? "/auth",
    meta: indexfGRdPY8dw3Meta || {},
    alias: indexfGRdPY8dw3Meta?.alias || [],
    redirect: indexfGRdPY8dw3Meta?.redirect,
    component: () => import("/workspace/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: calendarDIaBjG7u9CMeta?.name ?? "calendar",
    path: calendarDIaBjG7u9CMeta?.path ?? "/calendar",
    meta: calendarDIaBjG7u9CMeta || {},
    alias: calendarDIaBjG7u9CMeta?.alias || [],
    redirect: calendarDIaBjG7u9CMeta?.redirect,
    component: () => import("/workspace/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: indexFwHoINrwDKMeta?.name ?? "dashboard-workday_id",
    path: indexFwHoINrwDKMeta?.path ?? "/dashboard/:workday_id()",
    meta: indexFwHoINrwDKMeta || {},
    alias: indexFwHoINrwDKMeta?.alias || [],
    redirect: indexFwHoINrwDKMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/[workday_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index2lrBtCOh90Meta?.name ?? "dashboard",
    path: index2lrBtCOh90Meta?.path ?? "/dashboard",
    meta: index2lrBtCOh90Meta || {},
    alias: index2lrBtCOh90Meta?.alias || [],
    redirect: index2lrBtCOh90Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsJfxUZ0FKMeta?.name ?? "equipment-equipment_id",
    path: indexNsJfxUZ0FKMeta?.path ?? "/equipment/:equipment_id()",
    meta: indexNsJfxUZ0FKMeta || {},
    alias: indexNsJfxUZ0FKMeta?.alias || [],
    redirect: indexNsJfxUZ0FKMeta?.redirect,
    component: () => import("/workspace/pages/equipment/[equipment_id]/index.vue").then(m => m.default || m)
  },
  {
    name: add2lHBgjEr9QMeta?.name ?? "equipment-add",
    path: add2lHBgjEr9QMeta?.path ?? "/equipment/add",
    meta: add2lHBgjEr9QMeta || {},
    alias: add2lHBgjEr9QMeta?.alias || [],
    redirect: add2lHBgjEr9QMeta?.redirect,
    component: () => import("/workspace/pages/equipment/add.vue").then(m => m.default || m)
  },
  {
    name: indexpw3y5IdB0BMeta?.name ?? "equipment",
    path: indexpw3y5IdB0BMeta?.path ?? "/equipment",
    meta: indexpw3y5IdB0BMeta || {},
    alias: indexpw3y5IdB0BMeta?.alias || [],
    redirect: indexpw3y5IdB0BMeta?.redirect,
    component: () => import("/workspace/pages/equipment/index.vue").then(m => m.default || m)
  },
  {
    name: sendgrid_45tester5dokEmt9lTMeta?.name ?? "examples-sendgrid-tester",
    path: sendgrid_45tester5dokEmt9lTMeta?.path ?? "/examples/sendgrid-tester",
    meta: sendgrid_45tester5dokEmt9lTMeta || {},
    alias: sendgrid_45tester5dokEmt9lTMeta?.alias || [],
    redirect: sendgrid_45tester5dokEmt9lTMeta?.redirect,
    component: () => import("/workspace/pages/examples/sendgrid-tester.vue").then(m => m.default || m)
  },
  {
    name: twilio_45sms_45testerOX27vnPy7vMeta?.name ?? "examples-twilio-sms-tester",
    path: twilio_45sms_45testerOX27vnPy7vMeta?.path ?? "/examples/twilio-sms-tester",
    meta: twilio_45sms_45testerOX27vnPy7vMeta || {},
    alias: twilio_45sms_45testerOX27vnPy7vMeta?.alias || [],
    redirect: twilio_45sms_45testerOX27vnPy7vMeta?.redirect,
    component: () => import("/workspace/pages/examples/twilio-sms-tester.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTECrFyfG3xMeta?.name ?? "inspections-inspection_id",
    path: indexTECrFyfG3xMeta?.path ?? "/inspections/:inspection_id()",
    meta: indexTECrFyfG3xMeta || {},
    alias: indexTECrFyfG3xMeta?.alias || [],
    redirect: indexTECrFyfG3xMeta?.redirect,
    component: () => import("/workspace/pages/inspections/[inspection_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createJBzxGWekoSMeta?.name ?? "inspections-create",
    path: createJBzxGWekoSMeta?.path ?? "/inspections/create",
    meta: createJBzxGWekoSMeta || {},
    alias: createJBzxGWekoSMeta?.alias || [],
    redirect: createJBzxGWekoSMeta?.redirect,
    component: () => import("/workspace/pages/inspections/create.vue").then(m => m.default || m)
  },
  {
    name: indexIZgZHKXMoHMeta?.name ?? "inspections",
    path: indexIZgZHKXMoHMeta?.path ?? "/inspections",
    meta: indexIZgZHKXMoHMeta || {},
    alias: indexIZgZHKXMoHMeta?.alias || [],
    redirect: indexIZgZHKXMoHMeta?.redirect,
    component: () => import("/workspace/pages/inspections/index.vue").then(m => m.default || m)
  },
  {
    name: indexZnV8VF1ja4Meta?.name ?? "jobsites-jobsite_id",
    path: indexZnV8VF1ja4Meta?.path ?? "/jobsites/:jobsite_id()",
    meta: indexZnV8VF1ja4Meta || {},
    alias: indexZnV8VF1ja4Meta?.alias || [],
    redirect: indexZnV8VF1ja4Meta?.redirect,
    component: () => import("/workspace/pages/jobsites/[jobsite_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index8gHmhEyrfOMeta?.name ?? "jobsites-jobsite_id-tasks-task_id",
    path: index8gHmhEyrfOMeta?.path ?? "/jobsites/:jobsite_id()/tasks/:task_id()",
    meta: index8gHmhEyrfOMeta || {},
    alias: index8gHmhEyrfOMeta?.alias || [],
    redirect: index8gHmhEyrfOMeta?.redirect,
    component: () => import("/workspace/pages/jobsites/[jobsite_id]/tasks/[task_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpZ87DAld8sMeta?.name ?? "jobsites",
    path: indexpZ87DAld8sMeta?.path ?? "/jobsites",
    meta: indexpZ87DAld8sMeta || {},
    alias: indexpZ87DAld8sMeta?.alias || [],
    redirect: indexpZ87DAld8sMeta?.redirect,
    component: () => import("/workspace/pages/jobsites/index.vue").then(m => m.default || m)
  },
  {
    name: jobsites_45and_45vehiclesPlpQoZsan6Meta?.name ?? "maps-jobsites-and-vehicles",
    path: jobsites_45and_45vehiclesPlpQoZsan6Meta?.path ?? "/maps/jobsites-and-vehicles",
    meta: jobsites_45and_45vehiclesPlpQoZsan6Meta || {},
    alias: jobsites_45and_45vehiclesPlpQoZsan6Meta?.alias || [],
    redirect: jobsites_45and_45vehiclesPlpQoZsan6Meta?.redirect,
    component: () => import("/workspace/pages/maps/jobsites-and-vehicles.vue").then(m => m.default || m)
  },
  {
    name: callbackOmDf40drZUMeta?.name ?? "oauth-callback",
    path: callbackOmDf40drZUMeta?.path ?? "/oauth/callback",
    meta: callbackOmDf40drZUMeta || {},
    alias: callbackOmDf40drZUMeta?.alias || [],
    redirect: callbackOmDf40drZUMeta?.redirect,
    component: () => import("/workspace/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: accountciOgd6l6U4Meta?.name ?? "settings-account",
    path: accountciOgd6l6U4Meta?.path ?? "/settings/account",
    meta: accountciOgd6l6U4Meta || {},
    alias: accountciOgd6l6U4Meta?.alias || [],
    redirect: accountciOgd6l6U4Meta?.redirect,
    component: () => import("/workspace/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: categoriesngQVzCmgCiMeta?.name ?? "settings-categories",
    path: categoriesngQVzCmgCiMeta?.path ?? "/settings/categories",
    meta: categoriesngQVzCmgCiMeta || {},
    alias: categoriesngQVzCmgCiMeta?.alias || [],
    redirect: categoriesngQVzCmgCiMeta?.redirect,
    component: () => import("/workspace/pages/settings/categories.vue").then(m => m.default || m)
  },
  {
    name: crewseWhOM6uUqbMeta?.name ?? "settings-crews",
    path: crewseWhOM6uUqbMeta?.path ?? "/settings/crews",
    meta: crewseWhOM6uUqbMeta || {},
    alias: crewseWhOM6uUqbMeta?.alias || [],
    redirect: crewseWhOM6uUqbMeta?.redirect,
    component: () => import("/workspace/pages/settings/crews.vue").then(m => m.default || m)
  },
  {
    name: indexeK6rNZS014Meta?.name ?? "settings-exceptions-exception_id",
    path: indexeK6rNZS014Meta?.path ?? "/settings/exceptions/:exception_id()",
    meta: indexeK6rNZS014Meta || {},
    alias: indexeK6rNZS014Meta?.alias || [],
    redirect: indexeK6rNZS014Meta?.redirect,
    component: () => import("/workspace/pages/settings/exceptions/[exception_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index0NlQ4FDgWHMeta?.name ?? "settings",
    path: index0NlQ4FDgWHMeta?.path ?? "/settings",
    meta: index0NlQ4FDgWHMeta || {},
    alias: index0NlQ4FDgWHMeta?.alias || [],
    redirect: index0NlQ4FDgWHMeta?.redirect,
    component: () => import("/workspace/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: lenders0ONkurflXDMeta?.name ?? "settings-lenders",
    path: lenders0ONkurflXDMeta?.path ?? "/settings/lenders",
    meta: lenders0ONkurflXDMeta || {},
    alias: lenders0ONkurflXDMeta?.alias || [],
    redirect: lenders0ONkurflXDMeta?.redirect,
    component: () => import("/workspace/pages/settings/lenders.vue").then(m => m.default || m)
  },
  {
    name: indexa9NYmlF0R6Meta?.name ?? "settings-pto-pto_id",
    path: indexa9NYmlF0R6Meta?.path ?? "/settings/pto/:pto_id()",
    meta: indexa9NYmlF0R6Meta || {},
    alias: indexa9NYmlF0R6Meta?.alias || [],
    redirect: indexa9NYmlF0R6Meta?.redirect,
    component: () => import("/workspace/pages/settings/pto/[pto_id]/index.vue").then(m => m.default || m)
  },
  {
    name: tagsT2FXURAtvjMeta?.name ?? "settings-tags",
    path: tagsT2FXURAtvjMeta?.path ?? "/settings/tags",
    meta: tagsT2FXURAtvjMeta || {},
    alias: tagsT2FXURAtvjMeta?.alias || [],
    redirect: tagsT2FXURAtvjMeta?.redirect,
    component: () => import("/workspace/pages/settings/tags.vue").then(m => m.default || m)
  },
  {
    name: _91user_id_93yPjZ3TdGeoMeta?.name ?? "settings-users-user_id",
    path: _91user_id_93yPjZ3TdGeoMeta?.path ?? "/settings/users/:user_id()",
    meta: _91user_id_93yPjZ3TdGeoMeta || {},
    alias: _91user_id_93yPjZ3TdGeoMeta?.alias || [],
    redirect: _91user_id_93yPjZ3TdGeoMeta?.redirect,
    component: () => import("/workspace/pages/settings/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexgIPIiIeuS6Meta?.name ?? "settings-users",
    path: indexgIPIiIeuS6Meta?.path ?? "/settings/users",
    meta: indexgIPIiIeuS6Meta || {},
    alias: indexgIPIiIeuS6Meta?.alias || [],
    redirect: indexgIPIiIeuS6Meta?.redirect,
    component: () => import("/workspace/pages/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: vendorslzktSx9bI0Meta?.name ?? "settings-vendors",
    path: vendorslzktSx9bI0Meta?.path ?? "/settings/vendors",
    meta: vendorslzktSx9bI0Meta || {},
    alias: vendorslzktSx9bI0Meta?.alias || [],
    redirect: vendorslzktSx9bI0Meta?.redirect,
    component: () => import("/workspace/pages/settings/vendors.vue").then(m => m.default || m)
  },
  {
    name: indexztbNX0t2LuMeta?.name ?? "workorders-workorder_id",
    path: indexztbNX0t2LuMeta?.path ?? "/workorders/:workorder_id()",
    meta: indexztbNX0t2LuMeta || {},
    alias: indexztbNX0t2LuMeta?.alias || [],
    redirect: indexztbNX0t2LuMeta?.redirect,
    component: () => import("/workspace/pages/workorders/[workorder_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createtwDLh0TmqaMeta?.name ?? "workorders-create",
    path: createtwDLh0TmqaMeta?.path ?? "/workorders/create",
    meta: createtwDLh0TmqaMeta || {},
    alias: createtwDLh0TmqaMeta?.alias || [],
    redirect: createtwDLh0TmqaMeta?.redirect,
    component: () => import("/workspace/pages/workorders/create.vue").then(m => m.default || m)
  },
  {
    name: indexMszVj8d4c2Meta?.name ?? "workorders",
    path: indexMszVj8d4c2Meta?.path ?? "/workorders",
    meta: indexMszVj8d4c2Meta || {},
    alias: indexMszVj8d4c2Meta?.alias || [],
    redirect: indexMszVj8d4c2Meta?.redirect,
    component: () => import("/workspace/pages/workorders/index.vue").then(m => m.default || m)
  },
  {
    name: shop_45schedulerIaLhx40TgcMeta?.name ?? "workorders-shop-scheduler",
    path: shop_45schedulerIaLhx40TgcMeta?.path ?? "/workorders/shop-scheduler",
    meta: shop_45schedulerIaLhx40TgcMeta || {},
    alias: shop_45schedulerIaLhx40TgcMeta?.alias || [],
    redirect: shop_45schedulerIaLhx40TgcMeta?.redirect,
    component: () => import("/workspace/pages/workorders/shop-scheduler.vue").then(m => m.default || m)
  }
]