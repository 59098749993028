/**
 * Composable defining a repository for local in-application redirects after an OAuth authorization is completed.
 *
 * This assists with redirecting the user to the appropriate page after completing an OAuth authorization instead of
 * using a generic homepage redirect, especially as some integrations may not allow passing our internal redirect URL
 * as part of the request workflow.
 *
 * Internally uses the client's local storage as this is user activity based and does not need to be shared.
 */
export default () => {
  // Initialize the local storage composable immediately
  const storage = useLocalStorage<Record<string, string>>(
    "oauth-redirects",
    {}
  );

  function clear() {
    storage.value = {};
  }

  function get(code: string): string | undefined {
    return storage.value[code] ?? undefined;
  }

  function has(code: string) {
    return Object.prototype.hasOwnProperty.call(storage.value, code);
  }

  function remove(code: string) {
    delete storage.value[code];
  }

  function set(code: string, path: string) {
    storage.value[code] = path;
  }

  return { clear, get, has, remove, set };
};
