import * as Sentry from "@sentry/vue";
import { jwtDecode } from "jwt-decode";
import type { SupabaseJwtPayload } from "~/types/oauth";
import type { Database, Enums } from "~/types/supabase";

/**
 * Defines a Nuxt plugin integrating adding extra actions to the Supabase authentication state lifecycle.
 *
 * The plugin subscribes to authentication state lifecycle events to ensure the correct user information is
 * made available throughout the application, specifically:
 *
 * - Extracting the currently authenticated user's role from their JWT and exposing it as application state
 * - Storing user information to the Sentry context, which helps to better associate recorded errors with the user who was performing the action
 */
export default defineNuxtPlugin({
  name: "merl-auth",
  enforce: "pre",
  dependsOn: ["supabase"],
  async setup(nuxtApp) {
    // Define global state for the user role as provided in the JWT
    const userRole = useState<Enums<"app_role"> | null>(
      "merl_user_role",
      () => null
    );

    const oauthRedirectStorage = useOAuthRedirectStorage();
    const pendingOAuthAuthorizationsStorage =
      usePendingOAuthAuthorizationsStorage();

    /**
     * Set the updated user information to the Sentry context.
     *
     * Because this plugin runs for both the client and server context, and requires different underlying SDK integrations,
     * it mandates that we use a combination of checking the Nuxt context through the {@link process} global and dynamically
     * importing the correct SDK for the context.
     *
     * The compiler optimizes out these checks, so only the context relevant runtime code is included in the production build.
     */
    const setSentryUser = async (user) => {
      if (process.client) {
        const { setUser } = await import("@sentry/vue");

        setUser(user);
      } else if (process.server) {
        const { setUser } = await import("@sentry/node");

        setUser(user);
      }
    };

    // Subscribe to Supabase lifecycle events to refresh data during the app lifecycle
    const supabase = useSupabaseClient<Database>();
    supabase.auth.onAuthStateChange(async (event, session) => {
      switch (event) {
        case "INITIAL_SESSION":
        case "SIGNED_IN":
        case "TOKEN_REFRESHED":
        case "USER_UPDATED":
          if (session !== null) {
            // Set current user info to Sentry scope
            await setSentryUser({
              id: session.user.id,
              email: session.user.email,
              username: session.user.email,
            });

            const jwt = jwtDecode<SupabaseJwtPayload>(session.access_token);
            userRole.value = jwt.user_role || null;
          } else {
            await setSentryUser(null);
            userRole.value = null;
          }

          break;

        case "SIGNED_OUT":
          Sentry.captureMessage("User signed out");
          // Clear local stores
          oauthRedirectStorage.clear();
          pendingOAuthAuthorizationsStorage.clear();

          // Clear user from Sentry scope
          await setSentryUser(null);

          // Clear the user role from global state
          userRole.value = null;

          break;
      }
    });
  },
});
