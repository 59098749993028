import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAlert, LazyAvatar, LazyCard, LazyChip, LazyHistoryChangedFields, LazyLazy, LazyModal, LazyOverlay, LazyPagination, LazySidebar, LazyToast, LazyTooltip, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["Alert", LazyAlert],
["Avatar", LazyAvatar],
["Card", LazyCard],
["Chip", LazyChip],
["HistoryChangedFields", LazyHistoryChangedFields],
["Lazy", LazyLazy],
["Modal", LazyModal],
["Overlay", LazyOverlay],
["Pagination", LazyPagination],
["Sidebar", LazySidebar],
["Toast", LazyToast],
["Tooltip", LazyTooltip],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
