import * as Sentry from "@sentry/vue";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import type { Database } from "~/types/supabase";

/**
 * Defines a Nuxt plugin integrating the `@sentry/vue` SDK into the application.
 *
 * The plugin is only provided to the Nuxt client build due to the Sentry Vue SDK only being reliable in the client context.
 * There is a separate Nitro server plugin incorporating the `@sentry/node` package which is more appropriate for the server context.
 */
export default defineNuxtPlugin({
  name: "sentry",
  enforce: "pre",
  dependsOn: ["nuxt:router", "supabase"], // Initialize as early as we can, the router and Supabase are needed for the integrations list
  async setup(nuxtApp) {
    const config = useRuntimeConfig();

    // To enable the integration, we need a DSN and the build environment set to production
    if (
      !config.public.sentry.dsn ||
      config.public.sentry.environment !== "production"
    ) {
      return;
    }

    const router = useRouter();
    const supabase = useSupabaseClient<Database>();
    const lastErrorId = useState<string | undefined>(
      "sentry_last_error",
      () => undefined
    );

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: config.public.sentry.dsn,
      environment: config.public.sentry.environment,
      release:
        config.public.sentry.release === ""
          ? undefined
          : config.public.sentry.release,
      integrations: [
        supabaseIntegration(supabase, Sentry, {
          tracing: true,
          breadcrumbs: true,
          errors: true,
        }),
        Sentry.browserTracingIntegration({
          router,
          shouldCreateSpanForRequest: (url) =>
            !url.startsWith(`${config.public.supabase.url}/rest`),
        }),
      ],
      tracesSampleRate: 0.25,
      trackComponents: true,
      ignoreErrors: [
        "Unable to preload CSS for",
        "Signups not allowed for otp",
        "Token has expired or is invalid",
        "Failed to fetch dynamically imported module",
        "Importing a module script failed",
      ],
      denyUrls: [
        /connect\.facebook\.net/,
        /graph\.facebook\.com/,
        /assets\.pinterest\.com/,
        /www\.googleadservices\.com/,
        /pagead2\.googlesyndication\.com/,
        /www\.googletagmanager\.com/,
        /www\.google-analytics\.com/,
        /translate\.googleapis\.com/,
        /www\.gstatic\.com/,
        /px\.ads\.linkedin\.com/,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
      beforeSend(event, hint) {
        lastErrorId.value = event.event_id;

        return event;
      },
    });
  },
});
